/* eslint-disable */
import React from "react";
import g from "glamorous";
import { css } from "glamor";
import Layout from "../components/layout";
import Menu from "../components/Menu";
import Footer from "../components/Footer";
import AlgoliaLogo from "../components/AlgoliaLogo";
import { Helmet } from "react-helmet";
import { Container } from "glamorous-grid";
import { Link } from "gatsby";
import { FaHome } from "react-icons/fa";
import algoliasearch from "algoliasearch/lite";
import {
  InstantSearch,
  Hits,
  SearchBox,
  connectStateResults,
} from "react-instantsearch-dom";
import "instantsearch.css/themes/reset.css";

const Wrapper = g.main({
  maxWidth: `1000px`,
  margin: `0 auto`,
  paddingBottom: `4em`,
});

const Breadcrumb = g.ul({
  fontSize: `13px`,
  lineHeight: `20px`,
  marginLeft: `0 !important`,
  marginTop: `50px !important`,
  marginBottom: `20px !important`,
  fontWeight: `500`,
  letterSpacing: `.015em`,
});

const BreadcrumbItem = g.li({
  display: `inline`,
  "&:first-of-type": {
    "&::before": {
      content: `none`,
    },
  },
  "&::before": {
    content: `/`,
    padding: `0 .5em`,
  },
});

const cssBreadcrumbLink = css({
  color: `#3c4d68`,
  borderBottom: `none`,
});

const searchClient = algoliasearch(
  "SCNPXMXPL2",
  "062aae220c2e85e233bb7a4951e3a0fa"
);

export default () => (
  <Layout>
    <g.Div id="page-wrap" margin={`0 auto`} backgroundColor={`#fcfcfc`}>
      <Helmet>
        <title>Effectuer une recherche sur le site | Dr Cédric Kron</title>
        <meta
          name="description"
          content="Effectuez une recherche sur le site du Dr Cédric Kron, médecin esthétique et chirurgien plastique exerçant à Paris 17e arrondissement."
        />
        <html lang="fr" />
        <link
          rel="alternate"
          hreflang="en"
          href="https://www.dr-kron.com/en/search/"
        />
        <script type="application/ld+json">{` {
        "@context": "http://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": [{
        "@type": "ListItem",
        "position": 1,
        "name": "Effectuer une recherche sur le site",
        "item": "https://www.dr-kron.com/recherche/"
        }]
            }
  `}</script>
      </Helmet>
      <Menu current="recherche" pathEn="/en/search/" />

      <Wrapper>
        <Container css={{ padding: `0 15px !important`, minHeight: `40vw` }}>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/" {...cssBreadcrumbLink} title="Page d'accueil">
                <FaHome
                  css={{
                    fontSize: `1.2em`,
                    paddingBottom: `.2em`,
                    transform: `translateY(5px)`,
                  }}
                />
              </Link>
            </BreadcrumbItem>

            <BreadcrumbItem>Effectuer une recherche sur le site</BreadcrumbItem>
          </Breadcrumb>
          <InstantSearch indexName="dr-kron" searchClient={searchClient}>
            <div className="container" css={{ padding: `1px` }}>
              <SearchBox translations={{ placeholder: "Rechercher..." }} />

              <Results />
              <AlgoliaLogo />
            </div>
          </InstantSearch>
        </Container>
      </Wrapper>
      <Footer />
    </g.Div>
  </Layout>
);

function Hit({ hit }) {
  console.log({ hit });
  return (
    <Link to={hit.url.substring(23)} css={{ padding: `0`, margin: `0` }}>
      {hit.hierarchy.lvl0 && (
        <Title>
          {hit.hierarchy.lvl0.replace(new RegExp("&" + "#" + "x27;", "g"), "'")}
        </Title>
      )}
      {hit.hierarchy.lvl1 && (
        <P>
          <strong>
            {hit.hierarchy.lvl1.replace(
              new RegExp("&" + "#" + "x27;", "g"),
              "'"
            )}
          </strong>
        </P>
      )}
      {hit.content && (
        <P>
          {hit.content.replace(new RegExp("&" + "#" + "x27;", "g"), "'")}
          <span>...</span>
        </P>
      )}
      <Url>{hit.url_without_anchor}</Url>
    </Link>
  );
}

const Results = connectStateResults(({ searchState }) =>
  searchState && searchState.query ? <Hits hitComponent={Hit} /> : null
);

const Title = g.div({
  fontSize: `21px`,
  lineHeight: `28px`,
  fontWeight: `600`,
  color: `#3c4d68`,
});

const P = g.p({
  margin: `0`,
  padding: `0`,
});

const Url = g.div({
  fontSize: `12px`,
  fontWeight: `400`,
  opacity: `.65`,
  letterSpacing: `.01em`,
});

css.global(".ais-SearchBox-input", {
  width: "90% !important",
  marginRight: `10px`,
  marginTop: `0px !important`,
  marginBottom: `30px !important`,
  height: `40px !important`,
  fontSize: `28px !important`,
  paddingTop: `2px`,
  paddingLeft: `7px`,
  paddingBottom: `5px`,
  border: `1px solid rgba(73,105,126,0.08) !important`,
  boxShadow: `0 1px 7px rgba(0,0,0,.05) !important`,
  "::focus": {
    border: `1px solid rgba(73,105,126,0.18)`,
    boxShadow: `0 1px 9px rgba(0,0,0,.2)`,
    transition: `all 0.1s ease-in-out`,
    outline: `none !important`,
  },
  "@media(max-width: 767px)": {
    width: "50% !important",
  },
});

css.global('.ais-SearchBox-input[type="text"]', {});

css.global(".ais-Hits-list", {
  margin: "0 !important",
});

css.global(".ais-Hits-item", {
  marginBottom: "30px !important",
});

css.global(".ais-SearchBox-submit", {});

css.global(".ais-SearchBox-submit svg", {
  position: `absolute`,
  fill: `white`,
  marginTop: `-28px`,
  backgroundColor: "#3c4d68",
  borderRadius: `4px !important`,
  height: "22px",
  width: `22px`,
  padding: `8px`,
});

css.global(".ais-SearchBox-resetIcon", {
  display: `none`,
});
